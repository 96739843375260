import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import GatsbyLink from "../GatsbyLink";
import { ImagePass } from "../Image/ImagePass";
import "./RelatedProjects.scss";

const ProjectComponent = ({
  data: {
    allWordpressWpProjects: { edges: projects },
  },
  action,
}) => {
  const [featureImage, setFeatureImage] = useState(0);

  return (
    <section className="related-projects">
      <div className="related-projects-wrap">
        <div className="col">
          <div className="image-container">
            <ImagePass src={projects[featureImage]?.node?.featured_media} />
          </div>
        </div>
        <div className="col">
          <h2>
            <GatsbyLink to={action?.url} className="button--dark">
              {action?.title}
            </GatsbyLink>
          </h2>
          {projects && (
            <ul className="project-list">
              {Object.keys(projects).length > 0 &&
                projects?.map((project, index) => {
                  const projectNode = project?.node;
                  return (
                    <li
                      className="project-list__item"
                      onMouseEnter={() => setFeatureImage(index)}
                      key={index}
                    >
                      <GatsbyLink to={projectNode?.path}>
                        {projectNode.title}
                      </GatsbyLink>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

const RelatedProjects = (props) => {
  const postType = props.contentType ? props.contentType : "post";
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          }
          allWordpressWpProjects(
            sort: { fields: date, order: DESC }
            limit: 5
          ) {
            edges {
              node {
                ...ProjectListFields
              }
            }
          }
        }
      `}
      render={(data) => <ProjectComponent {...props} data={data} />}
    />
  );
};

export default RelatedProjects;
