import React from 'react';
import './Breadcrumb.scss';

export const Breadcrumb = ({ breadcrumb }) => {
  return (
    <section className='breadcrumb-component'>
      <div
        dangerouslySetInnerHTML={{ __html: breadcrumb }}
        className='breadcrumb-link'
      />
    </section>
  );
};
