import React from "react";
import "./Header.scss";
import { ImagePass } from "../../Image/ImagePass";
import HomePageImageQuery from "../../Image/HomePageImageQuery";
import GatsbyLink from "../../GatsbyLink";
import Carousel from "../../Carousel";
import { Breadcrumbs } from "../../Breadcrumbs";
import { decodeEntities } from "../../../utils/helpers";

const HeaderSlider = (props) => {
  const { slider, globalTitle, location, breadcrumbs, centre } = props;
  const settings = {
    container: "page-carousel",
    nav: true,
    mouseDrag: true,
    items: 1,
    controls: true,
    rewind: true,
    loop: false,
    mode: "gallery",
    autoplay: globalTitle ? true : false,
  };
  return (
    <>
      {location.pathname === "/" ? (
        <HomePageImageQuery className="home-background" />
      ) : null}
      <div className="page-slider">
        <Carousel settings={settings}>
          {slider.map((slide, slide_count) => (
            <div key={slide_count} className="slide">
              {!globalTitle && (
                <div className={`title-block${centre ? " centre" : ""}`}>
                  <div className="wrap">
                    <div className="inner">
                      {breadcrumbs && <Breadcrumbs location={location} />}
                      {slide_count == 0 ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      ) : (
                        <h2
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      )}
                      {slide.subHeader && (
                        <p
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.subHeader),
                          }}
                        />
                      )}
                      {slide.action && (
                        <GatsbyLink
                          to={slide.action.url}
                          className="action"
                          decode={true}
                        >
                          {slide.action.title}
                        </GatsbyLink>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {location.pathname === "/" && slide_count == 0 ? (
                <div className="place-holder" />
              ) : (
                <ImagePass src={slide.background} className="background" />
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export const Header = (props) => {
  const {
    compact,
    globalTitle,
    breadcrumbs,
    header,
    subHeader,
    centre,
  } = props;
  return (
    <section className={`page-header${compact ? " compact" : ""}`}>
      <HeaderSlider {...props} />
      {globalTitle && (
        <div className={`title-block${centre ? " centre" : ""}`}>
          <div className="wrap">
            <div className="inner">
              {breadcrumbs && <Breadcrumbs location={location} />}
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
              />
              {subHeader && (
                <p
                  className="sub-title"
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(subHeader),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
