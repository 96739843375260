import React from 'react';
import GatsbyLink from '../../GatsbyLink';
// import { Link } from 'gatsby';
import './LinkComponent.scss';

export const LinkComponent = ({ link_title, link_to_page, link_colour }) => {
  const toPage = () => {
    switch (link_to_page) {
      case 'artist':
        return '/the-artist';
      case 'works':
        return '/works';
      case 'artspace':
        return '/art-space-collective';
      case 'news':
        return '/news';
      default:
        return '/';
    }
  };

  return (
    <section className='link-component'>
      <div className='link-placement-helper first-col'></div>
      <div className='link-placement-helper second-col'>
        <GatsbyLink
          to={toPage()}
          className={link_colour === 'dark' ? 'button--dark' : 'button--light'}
          decode={true}
        >
          {link_title}
        </GatsbyLink>
      </div>
    </section>
  );
};
