import React, { useMemo } from "react";
import "./HomepageHeader.scss";
import { ImagePass } from "../../Image/ImagePass";

export const HomepageHeader = ({ heading, subHeading, images }) => {
  // returns a truely random array
  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = array.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  const shuffledItems = useMemo(() => shuffleArray(images), [images]);

  return (
    <section className="homepage-hero">
      <div className="homepage-hero-wrap">
        <div className="content">
          <h1
            dangerouslySetInnerHTML={{ __html: heading }}
            className="heading"
          />
          <span
            dangerouslySetInnerHTML={{ __html: subHeading }}
            className="subheading"
          />
        </div>

        <div className="background">
          <ImagePass src={shuffledItems[0]?.image} />
        </div>
      </div>
    </section>
  );
};
